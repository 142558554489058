




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { getComponentIdData } from '@/modules/common/api'
import { getColumnList } from '@/modules/column/api/index'
import {ComponentData, ListSell, LiveDecorate} from '@/modules/common/models'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox
  }
})
export default class ListContent extends Vue {
  @Prop({ default: '' }) type!: string
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderContentList()
  }

  isLoading: boolean = true
  contentList: Array<model.ContentList | LiveDecorate | ComponentData> = []
  groupTypes: Array<string> = [
    'latest_content',
    'latest_content_bigpic',
    'latest_content_compact',
    'hottest_content',
    'hottest_content_bigpic',
    'hottest_content_compact',
    'best_selling_content',
    'best_selling_content_bigpic',
    'best_selling_content_compact']
  typeLabel: { [key: string]: string } = {
    'column': '专栏',
    'article': '图文',
    'article_bigpic': '图文',
    'article_compact': '图文',
    'audio': '音频',
    'audio_bigpic': '音频',
    'audio_compact': '音频',
    'live': '直播',
    'live_list': '直播',
    'live_bigpic': '直播',
    'video': '视频',
    'video_bigpic': '视频',
    'video_compact': '视频',
    'latest_content': '最新内容',
    'latest_content_bigpic': '最新内容',
    'latest_content_compact': '最新内容',
    'hottest_content': '最热内容',
    'hottest_content_bigpic': '最热内容',
    'hottest_content_compact': '最热内容',
    'best_selling_content': '最畅销内容',
    'best_selling_content_bigpic': '最畅销内容',
    'best_selling_content_compact': '最畅销内容'
  }
  contentStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  contentType: string = ''
  componentId: any
  total: number = 0
  page: number = 1
  count: number = 10
  title: string = ''

  mounted() {
    window.scrollTo(0, 0)
    this.renderContentList()
  }

  get isHaveData() {
    return this.contentList.length
  }
  get getIsShowType() {
    if (this.groupTypes.includes(this.contentType)) {
      return true
    } else {
      return false
    }
  }

  get getMoreTips() {
    let typeTips = ''
    if (this.typeLabel.hasOwnProperty(this.contentType)) {
      typeTips = `全部${this.title ? this.title : this.typeLabel[this.contentType]} (${this.total})`
    } else {
      typeTips = '暂不支持该类型'
    }
    return typeTips
  }

  renderContentList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.contentType = this.$route.params.type
    this.componentId = this.$route.query.componentId
    this.title = this.$route.query.title && String(this.$route.query.title)
    this.isLoading = true
    this.getContentList()
  }

  getContentList() {
    if (this.groupTypes.includes(this.contentType) && this.componentId) {
      this.getComponentList()
    } else if (this.contentType === 'live') {
      this.getAliveContentList()
    } else if (this.contentType === 'column') {
      this.getColumnList()
    } else {
      this.getSingleContentList()
    }
  }

  getAliveContentList() {
    api.getAliveList({
      page: this.page,
      count: this.count,
      component_id: this.componentId
    }).then(data => {
      this.contentList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.contentList = []
      this.total = 0
      this.isLoading = false
      console.error('getAliveList = ', res)
    })
  }

  getColumnList() {
    getColumnList({
      page: this.page,
      count: this.count,
    }).then(data => {
      this.contentList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.contentList = []
      this.total = 0
      this.isLoading = false
    })
  }

  getSingleContentList() {
    api.getContentList({
      page: this.page,
      count: this.count,
      type: this.contentType
    }).then(data => {
      this.contentList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.contentList = []
      this.total = 0
      this.isLoading = false
      console.error('getSingleContentList = ', res)
    })
  }

  getComponentList() {
    getComponentIdData(this.componentId, {
      page: this.page,
      count: this.count
    }).then(data => {
      this.contentList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.contentList = []
      this.total = 0
      this.isLoading = false
      console.error('getComponentList =', res)
    })
  }

  getListSellItem(item: any) {
    let listSellItem: ListSell = {
      column_id: item.column_id,
      content_id: item.content_id,
      type: item.type || item.content_type,
      title: item.title,
      brief: item.brief,
      indexpic: item.indexpic,
      live_type: item.live_type,
      live_indexpic: item.live_indexpic,
      update_time: item.update_time,
      highest_price: Number(item.price),
      lowest_price: Number(item.price),
      comment_count: item.comment_count,
      view_count: item.view_count,
      live_state: item.live_state,
      start_timestamp: item.start_timestamp,
      subscribe_count: item.subscribe,
      course_id: item.course_id,
      course_class_id: item.course_class_id,
      original_price: item.original_price
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'contentList',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        title: `${this.title}`,
        componentId: `${this.componentId}`
      }
    })
  }
}
