import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
import { ApiList } from '@/modules/common/models/index'

// 专栏详情
export function getColumnDetail(id: string): Promise<model.CourseInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/free/column/detail/${id}`
  })
}
// 专栏内容列表
export function getColumnCountents(params:any): Promise<model.CourseInfo> {
  return phpApi({
    method: 'GET',
    url: '/h5/content/column/contents/all',
    params
  })
}
// 订阅专栏
export function doColumnSubscribe(params:any): Promise<model.CourseInfo> {
  return phpApi({
    method: 'GET',
    url: '/h5/content/user/subscribe',
    params,
    _apiName: '订阅'
  })
}

// 获取专栏列表
export function getColumnList(params: object): Promise<ApiList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/column/lists`,
    params
  })
}

